'use client';

import * as React from 'react';
import { motion } from 'framer-motion';
import { ResourceType, testimonialResource } from '@repo/common/services/wordpress';
import { useTranslation } from '../i18n/useTranslation';

const container = {
  hidden: {
    opacity: 0,
  },
  show: {
    opacity: 1,
    transition: {
      duration: 0.4,
    },
  },
};

const item = {
  hidden: {
    opacity: 0,
    translateY: 100,
    transition: {
      staggerChildren: 0.4,
    },
  },
  show: {
    opacity: 1,
    translateY: 0,
  },
};

export default function TestimonialSection({ testimonials }: { testimonials: ResourceType<typeof testimonialResource>[] }) {
  const { t } = useTranslation();

  return (
    <section id="testimonials" className="bg-blue-100/50 py-12">
      <div className="max-w-screen-xl mx-auto px-8 lg:px-16 py-4">
        <h2 className="text-3xl md:text-4xl font-bold mb-8">{t('landing.testimonials.header')}</h2>
        <motion.ul
          className="list-none m-0 w-full flex md:justify-center gap-12 md:items-center items-start overflow-x-auto overflow-y-visible md:overflow-x-visible snap-x snap-mandatory box-border px-12 md:px-0 py-8 md:py-0"
          variants={container}
          initial="hidden"
          whileInView="show"
          viewport={{ amount: 0.5 }}
        >
          {testimonials.map((testimonial) => (
            <motion.li
              key={testimonial.id}
              className="shadow-md border-black/10 border border-solid p-6 rounded-xl md:h-fit snap-center hover:translate-y-5 bg-white"
              variants={item}
              whileHover={{
                scale: 1.05,
                transition: { duration: 0.4 },
              }}
            >
              <div className="w-[50vw] md:max-w-[10em] text-left text-lg leading-6">
                {testimonial.acf.content}
              </div>
              <hr className="w-10 my-6 border-[#2C71F0]/50 border-solid mx-auto" />
              <div className="text-center font-medium text-lg">
                {testimonial.acf.company}
              </div>
            </motion.li>
          ))}
          {/* <motion.li
            className="shadow-md border-black/10 border border-solid p-6 rounded-xl md:h-fit snap-center bg-white"
            variants={item}
            whileHover={{
              scale: 1.05,
              transition: { duration: 0.4 },
            }}
          >
            <div className="w-[50vw] md:max-w-[10em] text-left text-lg leading-6">
              {testimonials[0]?.text}
            </div>
            <hr className="w-10 my-6 border-[#2C71F0]/50 border-solid mx-auto" />
            <div className="text-center font-medium text-lg">
              {testimonials[0]?.companyName}
            </div>
          </motion.li>
          <motion.li
            className="shadow-md border-black/10 border border-solid p-6 rounded-xl md:h-fit snap-center hover:translate-y-5 bg-white"
            variants={item}
            whileHover={{
              scale: 1.05,
              transition: { duration: 0.4 },
            }}
          >
            <div className="w-[50vw] md:max-w-[10em] text-left text-lg leading-6">
              {testimonials[1]?.text}
            </div>
            <hr className="w-10 my-6 border-[#2C71F0]/50 border-solid mx-auto" />
            <div className="text-center font-medium text-lg">
              {testimonials[1]?.companyName}
            </div>
          </motion.li>
          <motion.li
            className="shadow-md border-black/10 border border-solid p-6 rounded-xl md:h-fit snap-center hover:translate-y-5 bg-white"
            variants={item}
            whileHover={{
              scale: 1.05,
              transition: { duration: 0.4 },
            }}
          >
            <div className="w-[50vw] md:max-w-[10em] text-left text-lg leading-6">
              {testimonials[2]?.text}
            </div>
            <hr className="w-10 my-6 border-[#2C71F0]/50 border-solid mx-auto" />
            <div className="text-center font-medium text-lg">
              {testimonials[2]?.companyName}
            </div>
          </motion.li> */}
        </motion.ul>
      </div>
    </section>
  );
}
