'use client';

import { useTranslation } from '../i18n/useTranslation';

export default function ScrollToTestimonials() {
  const { t } = useTranslation();
  return (
    <button
      type="button"
      onClick={() => {
        const ref = document.getElementById('customer-testimonials');
        ref?.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }}
      className="group bg-black/30 px-3 md:py-1.5 rounded-xl text-xs md:text-sm shadow hover:shadow-lg backdrop-blur-md border border-white/10 border-solid flex items-center gap-2 transition-colors hover:bg-black/20 hover:border-white/20 cursor-pointer text-white"
    >
      <span className="material-symbols-outlined transform -translate-y-[1px] opacity-50 group-hover:opacity-75 transition-opacity text-lg">
        star
      </span>
      {t('landing.recommended')}
    </button>
  );
}
