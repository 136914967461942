import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/vendor-dashboard/src/app/[lang]/_landing-page-components/AboutSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/vendor-dashboard/src/app/[lang]/_landing-page-components/CustomerTestimonials.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/vendor-dashboard/src/app/[lang]/_landing-page-components/FinalOffer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/vendor-dashboard/src/app/[lang]/_landing-page-components/Footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/vendor-dashboard/src/app/[lang]/_landing-page-components/Header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/vendor-dashboard/src/app/[lang]/_landing-page-components/KeepQueryLink.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/vendor-dashboard/src/app/[lang]/_landing-page-components/ScrollToTestimonials.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/vendor-dashboard/src/app/[lang]/_landing-page-components/WhatWeDoSection.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.24_8a2a1eee516835a8040c05f34baf174e/node_modules/next/dist/client/image-component.js");
